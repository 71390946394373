@import './src/global.scss';
.button {
	display: inline-block;
	height: 60px;
	border: 1px solid var(--button-bg-alt);
	padding: 0 28px;
	outline: none;
	font-size: 16px;
	line-height: 60px;
	appearance: none;
	border-radius: 0;
	transition: all 0.3s;
	background: var(--button-bg);
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	font-family: var(--font-secondary);
	letter-spacing: 1.25px;
	&:hover {
		background: var(--button-bg-alt);
	}
}

a.button {
	color: #fff;
	text-decoration: none;
}
