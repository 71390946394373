@import './src/global.scss';
.layout {
	&__body,
	&__cta {
		@include responsiveUnit(margin-top, 48px, 128px);
	}

	&__cta {
		text-align: center;
	}
}

.headerBar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: var(--color-burnt);
	color: var(--color-white);
	padding: 16px 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: transform 0.3s;
	&.enter,
	&.exitActive {
		transform: translateY(-100%);
	}
	&.enterActive {
		transform: translateY(0);
	}
	&__cta {
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 1;
		color: #fff;
		text-transform: uppercase;
		font-family: var(--font-secondary);
		letter-spacing: 1.25px;
		svg {
			display: block;
			margin-left: 8px;
			position: relative;
			top: -1px;
			fill: #fff;
		}
	}
}
