@import './src/global.scss';
.navLink {
	font-family: var(--font-secondary);
	font-size: 32px;
	line-height: 1;
	transition: all 0.15s ease-in-out;
	text-transform: uppercase;
	color: var(--navLink-color);
	position: relative;
}

:global(.screen-large) .navLink {
	font-size: 16px;
	&.active {
		&:after {
			position: absolute;
			left: 0;
			right: 0;
			height: 3px;
			background: var(--navLink-color);
			content: '';
			top: -3px;
		}
	}
}
