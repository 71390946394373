@import './src/global.scss';
.masthead {
	&__body {
		max-width: 638px;
		margin-left: auto;
		margin-right: auto;
	}
}

.body {
	padding-top: 0;
}

.section {
	@include responsiveUnit(margin-top, 48px, 128px);
}

.map {
	.map {
		&__cell {
			padding: 0;
		}
		&__inner {
			@include responsiveUnit(padding-top, 48px, 128px);
			@include responsiveUnit(padding-bottom, 48px, 128px);
			background: url('https://d3bcmafqb1amu0.cloudfront.net/homepage/map@2x.jpg') center center no-repeat;
			background-size: auto 100%;
		}
	}
}
:global(.screen-small) {
	.map {
		.map {
			&__cell {
				padding: 0;
			}
			&__inner {
				padding-left: var(--grid-gutter);
				padding-right: var(--grid-gutter);
			}
		}
	}
}
