@import './src/global.scss';
.grid {
	display: flex;
	width: 100%;
	flex: 1 1 auto;
	max-width: 1280px;
	margin: 0 auto;
	&.stacked {
		flex-direction: column;
	}
	&.reverse {
		flex-direction: row-reverse;
	}
	&.wrap {
		flex-wrap: wrap;
	}
	&.stacked.reverse {
		flex-direction: column-reverse;
	}
}

.cell {
	padding: 0 calc(var(--grid-gutter) / 2);
	flex: 1;
}
