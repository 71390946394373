@import './src/global.scss';
.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	&__lower {
		margin-top: auto;
	}
	&__body {
		@include responsiveUnit(padding-top, 48px, 128px);
		@include responsiveUnit(padding-bottom, 48px, 128px);
	}
}

.lower {
	&__50year {
		background: var(--color-orange);
		padding: 36px 0;
		text-align: center;
	}
	&__50year_img {
		margin: 0 auto 24px;
	}
	&__footer {
		background: var(--color-lite-orange);
		position: relative;
		padding-top: 36px;
		&:before {
			position: absolute;
			top: -4px;
			left: 0;
			background: url('./tear@2x.png') repeat;
			background-size: 303px 18px;
			content: '';
			width: 100%;
			height: 18px;
		}
		.caliMilk {
			margin: 0 auto;
		}
		a {
			color: var(--color-body);
			font-size: 16px;
			line-height: 1.5;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

:global(.screen-small) .lower__footer {
	text-align: center;
	padding-top: 0;
}

.navGroup {
	&__header {
		font-size: 16px;
		font-family: var(--font-secondary);
		text-transform: uppercase;
		color: var(--color-burnt);
		letter-spacing: 1.25px;
		margin-bottom: 10px;
	}
	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			margin-top: 10px;
		}
	}
}
:global(.screen-small) .navGroup {
	margin-top: 36px;
}

.copyright {
	text-align: center;
	border-top: 1px solid #cd991a;
	padding: 16px 0;
	margin-top: 32px;
}
:global(.screen-large) .copyright {
	margin-top: 96px;
}

.socialLinks {
	display: flex;
	align-items: center;
	a {
		display: block;
	}
	a + a {
		margin-left: 8px;
	}
}
:global(.screen-small) .socialLinks {
	justify-content: center;
}
