@import './src/global.scss';
.masthead {
	--burger-color: var(--color-body);
	--navLink-color: var(--color-body);
	color: var(--color-body);
	text-align: center;
	&.hasBg {
		--color-body: var(--color-white);
		--burger-color: var(--color-white);
		--navLink-color: var(--color-white);
		@include responsiveUnit(padding-bottom, 56px, 128px);
		padding-bottom: 56px;
		background: top center no-repeat;
		background-size: cover;
		position: relative;
		& > * {
			position: relative;
		}
		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(#211c1c, 0.6%);
			content: '';
			pointer-events: none;
		}
		.title {
			color: var(--color-body);
		}
	}
	.title {
		margin-bottom: 8px;
		color: var(--color-burnt);
	}
	.injectedBackground {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		video {
			max-width: 100%;
			max-height: 100%;
			min-width: 100%;
			min-height: 100%;
			object-fit: cover;
		}
	}
}
:global(.screen-large) {
	.masthead.hasBg {
		background-size: 100%;
		background-attachment: fixed;
	}
}

.mobileHeader {
	position: relative;
	margin-bottom: 40px;
	.logo {
		margin: 0 auto;
		position: relative;
		z-index: 10;
		height: 88px;
		width: auto;
	}
	.burger {
		position: absolute;
		top: 24px;
		right: 24px;
	}
}

.nav {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0 48px;
	padding: 0;
	list-style: none;
	a {
		color: var(--color-body);
	}
	& > li {
		position: relative;
		padding: 8px 0;
	}
	& > li + li {
		margin-left: 32px;
	}
	.logo {
		padding: 0 8px;
		img {
			height: 140px;
			width: auto;
		}
	}
	ul {
		--transform-scale: 0.8;
		margin: 0;
		padding: 8px 0;
		list-style: none;
		position: absolute;
		top: 100%;
		left: 0;
		transform: scaleX(var(--transform-scale)) translateX(calc(-50% + 32px));
		transform-origin: top left;
		background: var(--color-orange);
		text-align: left;
		z-index: 999;
		pointer-events: none;
		opacity: 0;
		transition: all 0.15s;
		&.active {
			--transform-scale: 1;
			opacity: 1;
			pointer-events: auto;
		}
		a {
			display: block;
			color: var(--color-chocolate);
			padding: 8px 16px;
			white-space: nowrap;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.headline {
	max-width: 1080px;
	margin: 0 auto;
}

.body {
	max-width: 860px;
	margin: 16px auto 0;
}
