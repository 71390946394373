@import './src/global.scss';
.wrapper {
	display: block;
	max-width: 1080px;
	margin: 0 auto;
}

.row {
	justify-content: space-between;
	align-items: center;
	&__primary {
		width: 100%;
		max-width: 526px;
	}
	&__secondary {
		width: 100%;
		max-width: 415px;
	}
}

.row + .row {
	@include responsiveUnit(margin-top, 48px, 128px);
}

:global(.screen-small) {
	.wrapper {
		max-width: 526px;
	}
	.row {
		&__primary,
		&__secondary {
			max-width: none;
		}
		&__secondary {
			margin-top: 32px;
		}
	}
}
