@import './src/global.scss';
.listings {
	--listingsPerRow: 1;
	margin-top: calc(var(--grid-gutter) * -1);
	.listings {
		&__cell {
			margin-top: var(--grid-gutter);
			min-width: calc(1 / var(--listingsPerRow) * 100%);
			max-width: calc(1 / var(--listingsPerRow) * 100%);
		}
	}
}
:global(.screen-medium) {
	.listings {
		--listingsPerRow: 2;
	}
}
:global(.screen-large) {
	.listings {
		--listingsPerRow: 3;
	}
}

.card {
	background-color: #fff;
	padding: 16px;
	@include responsiveUnit(padding-top, 20px, 56px);
	@include responsiveUnit(padding-bottom, 20px, 56px);
}
