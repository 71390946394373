@import './src/global.scss';
.layout {
	&__section {
		@include responsiveUnit(margin-top, 48px, 128px);
	}
}

.valueProp {
	text-align: center;
	.image {
		margin: 0 auto 16px;
	}
}

.history {
	&__headline {
		@include responsiveUnit(margin-bottom, 16px, 64px);
		text-align: center;
		color: var(--color-burnt);
	}
}

.quote {
	.inner {
		max-width: 824px;
		margin: 0 auto;
		text-align: center;
	}
	&__headline {
		@include responsiveUnit(margin-bottom, 16px, 40px);
		color: var(--color-burnt);
	}
	&__cite {
		@include responsiveUnit(margin-top, 16px, 40px);
	}
	&.fancy {
		@include responsiveUnit(padding-top, 48px, 128px);
		@include responsiveUnit(padding-bottom, 48px, 128px);
		background: url('https://d3bcmafqb1amu0.cloudfront.net/who-we-are/quote_bg_417.png') center center no-repeat;
		background-size: auto 100%;
	}
}
