@import './src/global.scss';
.fourColWrap {
	justify-content: center;
	margin-top: -16px;
	margin-bottom: -16px;
	& > * {
		min-width: 312px;
		max-width: 312px;
		margin: 16px 0;
	}
}
