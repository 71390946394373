@import './src/global.scss';
.inlineNav {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;
	@include responsiveUnit(margin-bottom, 24px, 54px);
	li {
		font-size: 16px;
		line-height: 38px;
		position: relative;
		color: var(--color-chocolate);
		padding: 0 16px;
		cursor: pointer;
		outline: none;
		white-space: nowrap;
		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: #fff;
			content: '';
			border-radius: calc(38px / 2);
			transform: scale(0);
			transition: transform 0.15s;
		}
		&.active {
			&:before {
				transform: scale(1);
			}
		}
	}
	li + li {
		margin-left: 8px;
	}
}

.label {
	position: relative;
}
