@import './src/global.scss';
.row {
	&__secondary {
		position: relative;
	}
}

.outerRow + .outerRow {
	@include responsiveUnit(margin-top, 48px, 128px);
}

.text {
	background: #fff;
	padding: 32px 16px;
	position: relative;
	&:before {
		position: absolute;
		left: 0;
		bottom: -4px;
		background: url('./tear@2x.png') repeat;
		background-size: 303px 18px;
		content: '';
		width: 100%;
		height: 18px;
	}
	&.floatLeft,
	&.floatRight {
		position: absolute;
		top: 10%;
		padding: 48px;
		transform: translateY(-50%);
		min-width: 500px;
	}
	&.floatLeft {
		left: calc(var(--grid-gutter) / 2);
	}
	&.floatRight {
		right: calc(var(--grid-gutter) / 2);
	}
}

.cta {
	margin-top: 32px;
}
