@import './src/global.scss';
.body {
	@include responsiveUnit(padding-top, 24px, 48px);
	max-width: 861px;
	margin: 0 auto;
}

.article {
	@include responsiveUnit(margin-top, 24px, 48px);
	max-width: 630px;
	margin-left: auto;
	margin-right: auto;
}

.back {
	@include responsiveUnit(margin-top, 48px, 128px);
	text-align: center;
}
