@import './src/global.scss';
@mixin smoothScroll() {
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

.overlayNav {
	@include smoothScroll;
	position: fixed !important;
	top: 0;
	left: 0;
	display: block;
	width: 100vw;
	height: 100vh;
	background: var(--color-orange);
	z-index: 9;
}

.inner {
	padding-top: 120px;
	padding-bottom: 100px;
	&:before {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 100px;
		background: var(--color-orange);
		z-index: 10;
		content: '';
	}
}

.nav {
	--color-body: var(--color-chocolate);
	--navLink-color: var(--color-chocolate);
	margin: 0;
	padding: 0;
	list-style: none;
	a {
		display: inline-block;
		color: var(--color-chocolate);
	}
	& > li + li {
		margin-top: 24px;
	}
	ul {
		margin: 8px 0 0;
		padding: 0;
		list-style: none;
		a {
			display: block;
			padding: 8px;
		}
	}
}
