@import './src/global.scss';
.intro {
	max-width: 972px;
	margin: 0 auto;
	text-align: center;
}

.preHeadline {
	@include responsiveUnit(margin-bottom, 16px, 24px);
	color: var(--color-burnt);
}
