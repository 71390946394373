@import './src/global.scss';
.listings {
	--listingsPerRow: 1;
	margin-top: calc(var(--grid-gutter) * -1);
	.listings {
		&__cell {
			margin-top: var(--grid-gutter);
			min-width: calc(1 / var(--listingsPerRow) * 100%);
			max-width: calc(1 / var(--listingsPerRow) * 100%);
		}
	}
}
:global(.screen-medium) {
	.listings {
		--listingsPerRow: 2;
	}
}
:global(.screen-large) {
	.listings {
		--listingsPerRow: 3;
	}
}

.body {
	@include responsiveUnit(padding-top, 36px, 54px);
}

.listing {
	display: flex;
	flex-direction: column;
	background: #fff;
	height: 100%;
	color: var(--color-body);
	.listing {
		&__image {
			position: relative;
			padding-bottom: 54%;
			img {
				position: absolute;
				top: 0;
				left: 0;
				object-fit: cover;
			}
		}
		&__inner {
			@include responsiveUnit(padding, 16px, 32px);
			display: flex;
			flex-direction: column;
			flex: 1;
		}
		&__label {
			div {
				display: inline-block;
				font-size: 12px;
				line-height: 1;
				background: var(--color-burnt);
				padding: 8px 12px;
				border-radius: 2px;
				font-family: var(--font-secondary);
				text-transform: uppercase;
				color: #fff;
				letter-spacing: 1px;
			}
		}
		&__title {
			@include responsiveUnit(margin-bottom, 16px, 32px);
			margin-top: 8px;
		}
		&__cta {
			margin-top: auto;
			display: flex;
			align-items: center;
			color: var(--color-burnt);
			letter-spacing: 1px;
			svg {
				display: block;
				height: 0.8em;
				margin-left: 8px;
				fill: currentColor;
			}
		}
	}
}
