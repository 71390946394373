@import './src/global.scss';
@mixin headline() {
	font-family: var(--font-secondary);
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 400;
}

.fs {
	p,
	&.body {
		margin: 1em 0;
		line-height: 1.5;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		display: inline-block;
		position: relative;
		color: var(--color-body-link);
		text-decoration: underline;
	}
	&.contained {
		max-width: 688px;
		margin: 0 auto;
	}
}

.h1 {
	@include headline;
	@include responsiveUnit(font-size, 40px, 56px);
}

.h2 {
	@include headline;
	font-size: 40px;
}

.h3 {
	@include headline;
	font-size: 32px;
}

.fs1 {
	@include headline;
	@include responsiveUnit(font-size, 40px, 56px);
}

.fs2 {
	@include headline;
	@include responsiveUnit(font-size, 24px, 40px);
}

.fs3 {
	@include headline;
	@include responsiveUnit(font-size, 24px, 32px);
	line-height: 1.25;
}

.fs4 {
	font-size: 24px;
}

.fs5 {
	@include headline;
	font-size: 24px;
}

.fs6 {
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 4px;
}

.mega {
	@include headline;
	@include responsiveUnit(font-size, 40px, 72px);
}

.intro {
	@include responsiveUnit(font-size, 18px, 20px);
	line-height: 1.6;
}

.caption {
	font-size: 14px;
	line-height: 1.6;
	opacity: 0.5;
}

.fs2 + .body,
.fs2 + .intro {
	margin-top: 16px;
}

.fs3 + .body {
	margin-top: 8px;
}

.meta {
	opacity: 0.5;
	font-size: 14px;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 5px;
}

.left {
	text-align: left;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.justify {
	text-align: justify;
}

.noWrap {
	line-height: 1;
	white-space: nowrap;
}
