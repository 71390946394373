@import './src/global.scss';
.disclaimer {
	@include responsiveUnit(margin-top, 48px, 128px);
	max-width: 860px;
	margin-left: auto;
	margin-right: auto;
}

.video-button {
	cursor: pointer;
	background: none;
	padding: 0;
	border: none;
	position: relative;
	.icon {
		@include responsiveUnit(width, 56px, 72px);
		@include responsiveUnit(height, 56px, 72px);
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		padding: 0;
		img {
			width: 45%;
			transform: translateX(2px);
		}
	}
}
