@import './src/global.scss';
.wrapper {
	@include responsiveUnit(margin-top, 24px, 48px);
}

.logoBar {
	display: flex;
	flex-wrap: wrap;
	margin: -8px -16px;
	padding: 0;
	list-style: none;
	justify-content: center;
	align-items: center;
	li {
		padding: 8px 16px;
	}
}
