@import './src/global.scss';
.masthead {
	@include responsiveUnit(padding-bottom, 48px, 64px);
	transition: background-color 0.3s;
	position: relative;
	&:before {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: url('./bg_tile.png');
		content: '';
		pointer-events: none;
	}
	&__simpleImgTextBody {
		text-align: left;
	}
	&__cta {
		margin-top: 12px;
	}
}

.mobileMasthead {
	flex-direction: column-reverse;
}

.sliderNav {
	@include responsiveUnit(margin-top, 48px, 64px);
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		font-size: 16px;
		line-height: 38px;
		position: relative;
		color: var(--color-chocolate);
		padding: 0 16px;
		cursor: pointer;
		outline: none;
		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: #fff;
			content: '';
			border-radius: calc(38px / 2);
			transform: scale(0);
			transition: transform 0.15s;
		}
	}
	li + li {
		margin-left: 8px;
	}
	&__label {
		position: relative;
	}
	:global(.tns-nav-active) {
		&:before {
			transform: scale(1);
		}
	}
}

.learnMore {
	@include responsiveUnit(margin-top, 48px, 128px);
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
	&__grid {
		@include responsiveUnit(margin-top, -48px, -64px);
		justify-content: center;
	}
	&__cell {
		@include responsiveUnit(margin-top, 48px, 64px);
		min-width: 300px;
		max-width: 300px;
	}
	&__img {
		margin: 0 auto;
	}
}
