@import './src/global.scss';
.overlayVideo {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #000;
	z-index: 9999;
	transition: opacity 0.3s;

	&.enter,
	&.exitActive {
		opacity: 0;
	}

	&.enterActive {
		opacity: 1;
	}

	.video {
		width: 100% !important;
		height: 100% !important;
		padding-top: 0 !important;
	}

	.close {
		@include button-reset;
		display: flex;
		align-items: center;
		position: absolute;
		top: 32px;
		left: 32px;
		font-family: var(--font-secondary);
		text-transform: uppercase;
		z-index: 9999;
		color: #fff;
		letter-spacing: 1px;
		font-size: 20px;

		svg {
			display: block;
			transform: rotate(180deg);
			fill: #fff;
			margin-right: 8px;
		}
	}
}
